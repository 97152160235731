/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import TGLogo from '@images/tg-logo.png';

function SEO({ description, title, schemaMarkup, deepLinkUrl }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `,
  );

  const metaDescription = description || site.siteMetadata.description;

  const deepLink = `tiklagelsin://web/${deepLinkUrl}`;

  return (
    <Helmet title={title}>
      <meta property="al:ios:url" content={deepLink} />
      <meta property="al:ios:app_store_id" content="1534477610" />
      <meta property="al:ios:app_name" content="Tıkla Gelsin" />
      <meta property="al:android:url" content={deepLink} />
      <meta property="al:android:package" content="com.ataexpress.tiklagelsin" />
      <meta property="al:android:app_name" content="Tıkla Gelsin" />
      <meta name="description" content={metaDescription} />
      <meta name="yandex-verification" content="cbf7d05ab15f8e93" />

      {/* Sadece beta, pre-prod ortamlarında çalışmalı. */}
      <meta name="robots" content="noindex" />

      {schemaMarkup && <script type="application/ld+json">{JSON.stringify(schemaMarkup)}</script>}
      <meta property="og:image" content={TGLogo} />
      <meta property="og:image:width" content="600" />
      <meta property="og:image:width" content="600" />
    </Helmet>
  );
}

SEO.defaultProps = {
  description: '',
};

SEO.propTypes = {
  description: PropTypes.string,
  schemaMarkup: PropTypes.object,
  title: PropTypes.string,
  deepLinkUrl: PropTypes.string,
};

export default SEO;
